<template>
  <div class="list-f">
    <Title title="实施意见反馈" border></Title>
    <div class="xian"></div>
    <div>请您对民航领域有关标准和计量规范实施过程中需要反馈的意见提供给我们，并填写下列表格</div>
    <div class="from-box">
      <TableFrom ref="from" :data="fromData">
        <TableInput
          label="姓名"
          :labelWidth="130"
          labelAlign="right"
          required
          placeholder="请输入姓名"
          v-model="fromData.name"
        ></TableInput>
        <TableInput
          label="联系电话"
          :labelWidth="130"
          labelAlign="right"
          required
          :rules="phoneRules"
          placeholder="请输入联系电话"
          v-model="fromData.phone"
        ></TableInput>
        <TableInput
          label="工作单位"
          :labelWidth="130"
          labelAlign="right"
          placeholder="请输入工作单位"
          v-model="fromData.unit"
        ></TableInput>
        <TableInput
          label="标准规范名称"
          :labelWidth="130"
          labelAlign="right"
          required
          placeholder="请输入标准规范名称"
          v-model="fromData.contentName"
        ></TableInput>
        <TableInput
          label="反馈意见内容"
          :labelWidth="130"
          labelAlign="right"
          required
          type="textarea"
          placeholder="请输入反馈意见内容"
          v-model="fromData.content"
        ></TableInput>
      </TableFrom>
      <div class="btns" style="padding-bottom: 0;">
        <Buttom class="btn loging" :loading="submitLoading" @click.native="submitForm">
          <span>提交</span>
        </Buttom>
      </div>
    </div>
  </div>
</template>

<script>
import { feedbackAdd } from '@/api/web'
import Title from '@/components/title/Title'
import TableFrom from '@/components/from/TableFrom'
import TableInput from '@/components/from/TableInput'
export default {
  components: {
    Title,
    TableFrom,
    TableInput
  },
  name: 'ListC',
  data () {
    return {
      fromData: {
        name: '',
        phone: '',
        unit: '',
        contentName: '',
        content: ''
      },
      phoneRules: [
        { type: 'regular', verify: /^[1][3,4,5,7,8][0-9]{9}$/, msg: '手机号码格式不对' }
      ],
      submitLoading: false
    }
  },

  methods: {
    submitForm () {
      this.submitLoading = true
      this.$refs.from.fromVerify().then(resp => {
        if (resp.code !== '000') {
          this.submitLoading = false
          return
        }
        feedbackAdd(this.fromData).then(res => {
          this.submitLoading = false
          if (res.code === '000') {
            this.$msg('提交成功', 'success')
            this.fromData = {
              name: '',
              phone: '',
              unit: '',
              contentName: '',
              content: ''
            }
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.list-f{
  padding: 25px 75px 50px 75px;
  min-height: 520px;
  position: relative;
  font-size: 16px;
}
.xian{
  margin: 15px 0;
  height: 1px;
  background-color: #cdcdcd;
}
.from-box{
  width: 550px;
  margin: auto;
  padding: 30px 25px 0 0;
}
</style>
